<template>
	<section
	class="mx-5"
	>
		<v-row>
            <v-col
            cols="12"
            md="11"
            >
				<h2 class="headline">Catálogo de encargados</h2>
            </v-col>
        </v-row>
		<v-card class="mt-3">
			<v-card-text>
				<v-row>
					<v-col
					cols="12"
					md="2"
					sm="12"
					>
						<v-select
						v-model="$v.sede.$model"
						:error-messages="sedeErrors"
						label="Sede"
						:items="sedes"
						item-text="nombre_sede"
						item-value="id_sede"
						:loading="loadSede"
						>
						</v-select>
					</v-col>
					<v-col
					cols="12"
					md="2"
					sm="12"
					>
						<v-select
						v-model="$v.jornada.$model"
						:error-messages="jornadaErrors"
						label="Jornada"
						:items="jornadas"
						item-text="nombre_jornada"
						item-value="id_jornada"
						:loading="loadJornada"
						></v-select>
					</v-col>
					<v-col
					cols="12"
					md="4"
					sm="12"
					>
						<v-select
						v-model="$v.level.$model"
						:error-messages="nivelErrors"
						label="Nivel"
						:items="levels"
						item-text="nombre_nivel"
						item-value="id_nivel"
						:loading="loadLevel"
						></v-select>
					</v-col>
					<v-col
					cols="12"
					md="3"
					sm="12"
					>
						<v-select
						v-model="$v.grade.$model"
						:error-messages="gradeErrors"
						label="Grado/Carrera"
						:items="grades"
						item-text="nombre"
						item-value="id"
						:loading="loadGrade"
						></v-select>
					</v-col>
					<v-col
					cols="12"
					md="1"
					sm="12"
					>
						<v-select
						label="Sección"
						:items="sections"
						item-text="name"
						item-value="id"
						v-model="$v.section.$model"
						:error-messages="sectionErrors"
						:loading="loadSection"
						></v-select>
					</v-col>
				</v-row>
				<section class="d-flex justify-center">
					<div>
						<v-btn
						@click="getParentsCatalogue"
						>
							Consultar
						</v-btn>
					</div>
				</section>
			</v-card-text>
		</v-card>
		<v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card
				v-show="showParents"
				>
                    <v-card-text>
                        <v-row
                        justify="space-between"
						color="primary"
                        >
                            <v-col
                            cols="6"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                hide-details
                                single-line
                                >
                                </v-text-field>
                            </v-col>
							<v-col
							cols='3'
							>
								<div class="d-flex align-center justify-end">
									<div>
										<v-checkbox
										v-model="authorizer"
										label="Seleccionar todos"
										/>
									</div>
									<div class="ml-3">
										<v-btn
										@click="setAuthorization()"
										>
											Autorizar
										</v-btn>
									</div>
								</div>
							</v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="parents"
                        :search="search"
                        loading=true
                        :loading-text="loadingText"
                        >
							<template v-slot:item.authorized="{ item }">
								<v-simple-checkbox
								:ripple="false"
								v-model="item.authorized"
								></v-simple-checkbox>
							</template>
							<template v-slot:item.actions="{ item }">
                                <v-menu
                                bottom
                                left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                        @click="edit(item)"
										v-can="'encargados-edit'"
                                        >
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
										<v-list-item
                                        @click="showAlert(item)"
										v-can="'encargados-delete'"
                                        >
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
										<v-list-item
                                        @click="createUser(item)"
										v-can="'encargados-create'"
										v-if="item.user_id == null"
                                        >
                                            <v-list-item-title>Crear usuario</v-list-item-title>
                                        </v-list-item>
										<v-list-item
                                        @click="suspendParent(item)"
										v-can="'encargados-delete'"
										v-if="item.user_id != null"
                                        >
                                            <v-list-item-title
											v-if="!item.is_suspended"
											>
												Suspender
											</v-list-item-title>
											<v-list-item-title
											v-else
											>
												Habilitar
											</v-list-item-title>
                                        </v-list-item>
										<v-list-item
                                        @click="showResetPassword(item)"
										v-can="'encargados-edit'"
										v-if="item.user_id != null"
                                        >
                                            <v-list-item-title>Reiniciar clave</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

		<v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        >
			<v-card>
				<v-card-title>
					<span class="headline">{{dialogName}} encargado</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col
						cols="4"
						>
							<v-text-field
							label="Carnet estudiante"
							v-model="$v.item.carnet_alumno.$model"
							:error-messages="carnetErrors"
							:loading="isLoadingCarnet"
							:disabled="!create"
							@keypress.enter="searchStudent"
							></v-text-field>
						</v-col>
						<v-col>
							<v-text-field
							label="Nombre estudiante"
							v-model="item.nombre_alumno"
							:disabled="dsb"
							></v-text-field>
						</v-col>
					</v-row>
					<v-text-field
					label="Nombre encargado"
					v-model="$v.item.nombre_encargado.$model"
					:error-messages="nombreEncargadoErrors"
					></v-text-field>
					<v-text-field
					label="Correo electrónico encargado"
					v-model="$v.item.email_encargado.$model"
					:error-messages="emailEncargadoErrors"
					></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
					color="blue darken-1"
					text
					@click="dialog = false"
					>
						Cancelar
					</v-btn>
					<v-btn
					color="blue darken-1"
					text
					@click="submit"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
        </v-dialog>

		<v-dialog
        v-model="resetPassDialog"
        persistent
        max-width="600px"
        >
			<v-card>
				<v-card-title>
					<span class="headline">Reinciar clave</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col>
							<v-text-field
							label="Nueva clave"
							v-model="$v.passReset.password.$model"
							:error-messages="passwordErrors"
							>
							</v-text-field>
						</v-col>
					</v-row>
					<section class="d-flex">
						<div>
							<v-checkbox
							label="Cambiar clave al iniciar sesión "
							v-model="passReset.change"
							></v-checkbox>
						</div>
					</section>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
					color="blue darken-1"
					text
					@click="resetPassDialog = false"
					>
						Cancelar
					</v-btn>
					<v-btn
					color="blue darken-1"
					text
					@click="resetPassword"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-fab-transition>
			<v-tooltip top>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
					color="pink"
					dark
					v-bind="attrs"
					v-on="on"
					fixed
					bottom
					right
					fab
					@click="dialog = true"
					v-can="'encargados-create'"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</template>
				<span>Agregar encargado</span>
			</v-tooltip>
        </v-fab-transition>

		<QuestionAlert
        :alert="onAlert"
        question="¿Esta seguro de eliminar el encargado?"
        @close="onAlert = false"
        @execute="deleteItem"
        />

		<ErrorHandler
		:notify="error"
		/>
	</section>
</template>

<script>
	import {mapMutations,mapState} from "vuex"
	import ErrorHandler from '@/components/ErrorHandler.vue'
	import { validationMixin } from 'vuelidate'
    import { required,email } from 'vuelidate/lib/validators'

	import QuestionAlert from '@/components/QuestionAlert.vue'

	export default {
		mixins: [validationMixin],
		data() {
			return {
				passReset: {
					user_id: null,
					password: '',
					change: ''
				},
				headers: [
                    {
                        text: 'Carnet alumno',
                        value: 'carnet_alumno'
                    },
                    {
                        text: 'Nombre alumno',
                        value: 'nombre_alumno'
                    },
                    {
                        text: 'Nombre encargado',
                        value: 'nombre_encargado'
                    },
                    {
                        text: 'Correo electrónico encargado',
                        value: 'email_encargado'
                    },
					{
                        text: 'Autorización',
                        value: 'authorized'
                    },
                    {
                        text: 'Opciones',
                        value: 'actions',
                        sortable: false
                    },
                ],
				error: {error: ''},
				loadingText: '',
                parents: [],
                search: '',
				dialog:false,
				create: true,
				item: {
					id: null,
					carnet_alumno: '',
					nombre_alumno: '',
					nombre_encargado: '',
					email_encargado: ''
				},
				itemToDelete: null,
				onAlert: false,
				isLoadingCarnet: false,
				dsb: true,
				authorizer: false,
				sedes: [],
                sede: '',
                jornada: '',
                level: '',
                grade: '',
                jornadas: [],
                levels: [],
                grades: [],
                section: null,
                sections: [],
                loadSede: false,
                loadJornada: false,
                loadLevel: false,
                loadGrade: false,
                loadSection: false,
				showParents: false,
				resetPassDialog: false
			}
		},
		validations: {
			item: {
				carnet_alumno: {required},
                nombre_encargado: {required},
                email_encargado: {required,email}
			},
			sede: {required},
            jornada: {required},
            level: {required},
            grade: {required},
            section: {required},
			passReset: {
				password: {required}
			}
        },
		computed: {
			...mapState(['schoolCycle']),
			isNew(){
				return this.create
			},
			dialogName(){
				return this.create ? 'Agregar nuevo' : 'Editar'
			},
			carnetErrors () {
                const errors = []
                if (!this.$v.item.carnet_alumno.$dirty) return errors
                !this.$v.item.carnet_alumno.required && errors.push('Campo requerido')
                return errors
            },
			nombreEncargadoErrors () {
                const errors = []
                if (!this.$v.item.nombre_encargado.$dirty) return errors
                !this.$v.item.nombre_encargado.required && errors.push('Campo requerido')
                return errors
            },
			emailEncargadoErrors () {
                const errors = []
                if (!this.$v.item.email_encargado.$dirty) return errors
                !this.$v.item.email_encargado.required && errors.push('Campo requerido')
				!this.$v.item.email_encargado.email && errors.push('Escriba un correo electrónico valido')
                return errors
            },
			sedeErrors () {
                const errors = []
                if (!this.$v.sede.$dirty) return errors
                !this.$v.sede.required && errors.push('Elige la sede')
                return errors
            },
            jornadaErrors () {
                const errors = []
                if (!this.$v.jornada.$dirty) return errors
                !this.$v.jornada.required && errors.push('Elige la jornada')
                return errors
            },
            nivelErrors () {
                const errors = []
                if (!this.$v.level.$dirty) return errors
                !this.$v.level.required && errors.push('Elige el nivel')
                return errors
            },
            gradeErrors () {
                const errors = []
                if (!this.$v.grade.$dirty) return errors
                !this.$v.grade.required && errors.push('Elige el/la grado/carrera')
                return errors
            },
            sectionErrors () {
                const errors = []
                if (!this.$v.section.$dirty) return errors
                !this.$v.section.required && errors.push('Elige la sección')
                return errors
            },
			passwordErrors () {
                const errors = []
                if (!this.$v.passReset.password.$dirty) return errors
                !this.$v.passReset.password.required && errors.push('Campo requerido')
                return errors
            },
		},
		methods: {
			...mapMutations(['setLayout','setLoading','setNotify']),
			getParentsCatalogue(){
				this.showParents = false
				this.$v.$touch()

				if(!this.$v.sede.$invalid
					&& !this.$v.jornada.$invalid
					&& !this.$v.level.$invalid
					&& !this.$v.grade.$invalid
					&& !this.$v.section.$invalid
				){
					this.setLoading(true)
					this.parents = []
					this.loadingText = 'Cargando información ...'

					this.axios.post('/parents',{
						sede_id: this.sede,
						jornada_id: this.jornada,
						nivel_id: this.level,
						grado_id: this.grade,
						seccion_id: this.section,
						cycle: this.schoolCycle
					})
					.then( (response) => {
						this.$v.$reset()
						this.setLoading(false)
						this.parents = response.data.parents
						this.showParents = true

						if(this.parents.length <= 0){
							this.showParents = false
							this.setNotify({'show':true,
							'color':'primary',
							'message':'No se encontró información',
							'time':5000})

							this.loadingText = 'No se encontró información.'
						}

					})
					.catch( (error) => {
						this.showParents = false
						this.setLoading(false)

						console.log(error)
					})
				}
            },
			edit(item){
				this.item.id = item.id
				this.item.carnet_alumno = item.carnet_alumno
				this.item.nombre_alumno = item.nombre_alumno
				this.item.nombre_encargado = item.nombre_encargado
				this.item.email_encargado = item.email_encargado

				this.create = false
				this.dialog = true
			},
			submit(){
				this.$v.$touch()

				if(this.create == true){
					if(!this.$v.item.carnet_alumno.$invalid
						&& !this.$v.item.nombre_encargado.$invalid
						&& !this.$v.item.email_encargado.$invalid
					){
						this.save()
					}
				}else{
					if(!this.$v.item.nombre_encargado.$invalid
						&& !this.$v.item.email_encargado.$invalid
					){
						this.update()
					}
				}
			},
			save(){
				this.setLoading(true)

				this.axios.post('/catalogue/parents',this.item)
				.then((response) => {
					this.setLoading(false)
					this.dialog = false

					this.setNotify({'show':true,
									'color':'success',
									'message':response.data.message,
									'time':5000})
					this.parents.push(response.data.item)
				})
				.catch((error) => {
					this.setLoading(false)

					this.error = {  'show':true,
                                    'color':'warning',
                                    'error': error,
                                    'time':5000
                                }
				})
			},
			update(){
				this.setLoading(true)

				this.axios.put('/catalogue/parents/'+this.item.id,this.item)
				.then((response) => {
					this.setLoading(false)
					this.dialog = false

					this.setNotify({'show':true,
									'color':'success',
									'message':response.data.message,
									'time':5000})

					this.getParentsCatalogue()
				})
				.catch((error) => {
					this.setLoading(false)
					console.log(error)
				})
			},
			deleteItem(){
				this.onAlert = false
                this.setLoading(true)

                this.axios.delete('/catalogue/parents/'+this.itemToDelete.id)
                .then( (response) => {
					this.itemToDelete = null
                    this.setLoading(false)
                    this.setNotify({'show':true,
                                    'color':'success',
                                    'message':response.data.message,
                                    'time':5000})
					this.search = ''
                    this.getParentsCatalogue()
                })
                .catch( (error) => {
                    this.setLoading(false)
                    console.log(error)
                })
            },
			showAlert(item) {
                this.itemToDelete = item
                this.onAlert = true
            },
			searchStudent() {
				this.isLoadingCarnet = true

				this.axios.post('/students/search',{
					carnet: this.item.carnet_alumno
				})
				.then( (response) => {
					this.isLoadingCarnet = false

					if(response.data.student == null){
						this.item.carnet_alumno = ''
						this.item.nombre_alumno = ''

						this.setNotify({'show':true,
                                    'color':'warning',
                                    'message':"Alumno no encontrado",
                                    'time':5000})
					}else{
						this.item.nombre_alumno = response.data.student.fullname
					}
				})
				.catch( (error) => {
					this.isLoadingCarnet = false
                    console.log(error)
                })
			},
			createUser(item) {
				this.setLoading(true)

				this.axios.post('/parents/createuser',item)
				.then( (response) => {
					this.setLoading(false)
					this.setNotify({'show':true,
								'color':'success',
								'message':response.data.message,
								'time':5000})

					item.user_id = response.data.user.id
					this.getParentsCatalogue()
				})
				.catch( (error) => {
					this.setLoading(false)
                    this.error = {  'show':true,
                                    'color':'warning',
                                    'error': error,
                                    'time':5000
                                }
                })
			},
			suspendParent(item) {
				this.setLoading(true)

				this.axios.post('/parents/suspendunsuspend',item)
				.then( (response) => {
					this.setLoading(false)
					this.setNotify({'show':true,
								'color':'success',
								'message':response.data.message,
								'time':5000})

					this.getParentsCatalogue()
				})
				.catch( (error) => {
					this.setLoading(false)
                    console.log(error)
                })
			},
			setAuthorization() {
				if(this.parents.length > 0){
					this.setLoading(true)

					this.axios.post('/parents/setauthorization',{
						parents: this.parents
					})
					.then((response) => {
						this.setLoading(false)
						this.getParentsCatalogue()

						this.setNotify({'show':true,
						'color':'success',
						'message':response.data.message,
						'time':5000})
					})
					.catch((error) => {
						this.setLoading(false)

						console.log(error)
					})
				}
			},
			getDropdownsData(){
                this.loadSede = true
                this.loadJornada = true
                this.loadLevel = true

                this.axios.get('/getdropdownsdata')
                .then( (response) => {
                    this.loadSede = false
                    this.loadJornada = false
                    this.loadLevel = false

                    this.sedes = response.data.sedes
                    this.jornadas = response.data.jornadas
                    this.levels = response.data.niveles
                })
                .catch( (error) => {
                    this.loadSede = false
                    this.loadJornada = false
                    this.loadLevel = false
                    console.log(error)
                })
            },
            getGrades(){
                this.loadGrade = true

                this.axios.get('/getgrades/'+this.level)
                .then( (response) => {
                    this.loadGrade = false

                    this.grades = response.data.grados
                    console.log(this.grades)
                })
                .catch( (error) => {
                    this.loadGrade = false
                    console.log(error)
                })
            },
			getSections(){
                if(this.level != '' && this.grade != ''){
                    this.loadSection = true

                    let grades = this.grades.filter((grade) => {
                        return grade.id == this.grade
                    });

                    this.sections = grades[0].sections

                    this.loadSection = false
                }
            },
			showResetPassword(item)
			{
				this.passReset.user_id = item.user_id
				this.resetPassDialog = true
			},
			resetPassword()
			{
				this.$v.$touch()

				if(!this.$v.passReset.$invalid){
					this.resetPassDialog = false
					this.setLoading(true)
	
					this.axios.put('/users/resetpassword/'+this.passReset.user_id,this.passReset)
					.then((response) => {
						this.setLoading(false)
	
						this.setNotify({'show':true,
										'color':'success',
										'message':response.data.message,
										'time':5000})
					})
					.catch((error) => {
						this.setLoading(false)
						console.log(error)
					})
				}
			}
		},
		created(){
			this.getDropdownsData()
            this.setLayout('main-layout')
        },
		watch: {
            "dialog": function() {
				if(!this.dialog){
					this.item.carnet_alumno = ''
					this.item.nombre_alumno = ''
					this.item.nombre_encargado = ''
					this.item.email_encargado = ''
					this.item.id = null
					this.create = true
					this.$v.$reset()
				}
			},
			"authorizer": function() {
				if(this.authorizer){
					this.parents.filter((parent) => {
						parent.authorized = true
					});
				}else{
					this.parents.filter((parent) => {
						parent.authorized = false
					});
				}
			},
			"level": function(){
                this.getGrades()
            },
            "grade": function(){
                if(this.grade != ''){
                    this.sections = []
                    this.getSections()
                }
            },
			"schoolCycle": function(){
				this.getParentsCatalogue()
			},
			"resetPassDialog": function() {
				if(!this.resetPassDialog) {
					this.passReset.user_id
					this.passReset.password = ''
					this.passReset.change = ''
					this.$v.$reset()
				}	
			}
		},
		components: {
			QuestionAlert,
			ErrorHandler
		}
	}
</script>
